import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.1.0/index.vue';
import HotelCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-city-selector/1.1.0/index.vue';
import DomesticTicketAirportSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.2.2/index.vue'
export default {
    data () {
        const validateHotelDateArr = (rule, value, callback) => {
            if (this.hotelParams.hotelCheckInDate === '' || this.hotelParams.hotelCheckOutDate === '') {
                callback(new Error('请输入入住日期及离店日期'))
            } else {
                callback()
            }
        }
        return {
            activeName: 'first',
            //flightType: 1,
            //flightFromStation: null,
            //flightToStation: null,
            //flightStartTime: '',
            //flightEndTime: '',
            flightParams: {
                flightType: 1,
                flightFromStation: null,
                flightToStation: null,
                flightStartTime: '',
                flightEndTime: '',
            },
            flightPickerOptions: {
                disabledDate: this.flightDisabledDate
            },
            flightEndPickerOptions: {
                disabledDate: this.flightEndDisabledDate
            },
            flightRule: {
                flightType: [
                    { required: true, message: '请选择航班类型', trigger: 'change' },
                ],
                flightFromStation: [
                    { required: true, message: '请输入出发城市', trigger: 'blur' },
                ],
                flightToStation: [
                    { required: true, message: '请输入到达城市', trigger: 'blur' },
                ],
                flightStartTime: [
                    { required: true, message: '请输入出发时间', trigger: 'blur' },
                ],
                flightEndTime: [
                    { required: true, message: '请输入返回日期', trigger: 'blur' },
                ],
            },

            // hotelCitySelector: '',
            //hotelKeyword: '',
            //hotelStarType: '',
            hotelParams: {
                hotelCitySelector: null,
                hotelKeyword: '',
                hotelStarType: '',
                hotelDateArr: [],
                hotelCheckInDate: '',
                hotelCheckOutDate: '',
            },
            hotelRule: {
                hotelCitySelector: [
                    { required: true, message: '请输入目的地', trigger: 'blur' },
                ],
                hotelDateArr: [
                    { required: true, message: '请输入入住日期及离店日期', trigger: 'blur' },
                    { validator: validateHotelDateArr, trigger: 'blur' }
                ]
            },
            hotelPickerOptions: {
                disabledDate: this.hotelDisabledDate,
                onPick: this.hotelOnPick
            },
            timeOptionRange: null,
            hotelStarList: [
                {
                    value: '',
                    label: "不限"
                },
                {
                    value: '2',
                    label: "经济型"
                },
                {
                    value: '3',
                    label: "舒适型"
                },
                {
                    value: '4',
                    label: "高档型"
                },
                {
                    value: '5',
                    label: "豪华型"
                }
            ],
            hotelNight: 0,

            trainParams: {
                fromStation: null,
                toStation: null,
                time: '',
                onlySearch: 0
            },
            ruleRule: {
                fromStation: [
                    { required: true, message: '请输入出发地', trigger: 'blur' },
                ],
                toStation: [
                    { required: true, message: '请输入目的地', trigger: 'blur' },
                ],
                time: [
                    { required: true, message: '请输入出发时间', trigger: 'change' },
                ],
            },
            pickerOptions: {
                disabledDate: this.disabledDate
            },

            canSubmit: true
        }
    },
    components: {
        TrainCitySelector,
        HotelCitySelector,
        DomesticTicketAirportSelector,
    },
    created () {},
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {
        'flightParams.flightFromStation'(newVal) {
            this.$refs['flightForm'].validateField('flightFromStation')
        },
        'flightParams.flightToStation'(newVal) {
            this.$refs['flightForm'].validateField('flightToStation')
        },
        'hotelParams.hotelCitySelector'(newVal) {
            this.$refs['hotelForm'].validateField('hotelCitySelector')
        },
        'trainParams.fromStation'(newVal) {
            this.$refs['trainForm'].validateField('fromStation')
        },
        'trainParams.toStation'(newVal) {
            this.$refs['trainForm'].validateField('toStation')
        }
    },
    computed: {},
    filters: {},
    methods: {
        handleClick() {},
        flightDisabledDate(time) {
            if (this.flightParams.flightEndTime === '' || this.flightParams.flightEndTime === null) {
                return (time.getTime() < Date.now() - 8.64e7)
            } else {
                return (time.getTime() < (Date.now() - 8.64e7) || time.getTime() > (new Date(this.flightParams.flightEndTime).getTime() - 8.64e7))
            }
        },
        flightEndDisabledDate(time) {
            if (this.flightParams.flightStartTime === '' || this.flightParams.flightStartTime === null) {
                return (time.getTime() < Date.now() - 8.64e7)
            } else {
                return time.getTime() < (new Date(this.flightParams.flightStartTime).getTime() - 8.64e7)
            }
        },
        changeFlightAdr() {
            let flightFromStation = Object.assign({}, this.flightParams.flightFromStation)
            let flightToStation = Object.assign({}, this.flightParams.flightToStation)
            this.flightParams.flightFromStation = flightToStation
            this.flightParams.flightToStation = flightFromStation
            this.$set(this.flightParams, 'flightFromStation', flightToStation)
            this.$set(this.flightParams, 'flightToStation', flightFromStation)
        },
        changeFlightType() {
            this.$set(this.flightParams, 'flightEndTime', '')
        },
        searchFlight() {
            this.$refs['flightForm'].validate((valid) => {
                if (valid) {
                    this.$router.push({
                        name: 'admin-flight-search',
                        query: {
                            fromStationCode: this.flightParams.flightFromStation.code,
                            fromStationName: this.flightParams.flightFromStation.name,
                            toStationCode: this.flightParams.flightToStation.code,
                            toStationName: this.flightParams.flightToStation.name,
                            startDate: this.flightParams.flightStartTime,
                            endDate: this.flightParams.flightEndTime,
                            flightType: this.flightParams.flightType,
                            evectionNo: '',
                            redirectType: '000',
                            evectionType: 1, // 1是个人出差，2是出差单出差
                            canShowSendMsg: 1, // 是否可以显示勾选短信 0是不可以， 1是可以
                        }
                    })
                }
            })
        },
        hotelDisabledDate(time) {
            // return (time.getTime() < Date.now() - 8.64e7)
            // 获取选中时间
            let timeOptionRange = this.timeOptionRange;
            // 获取时间范围(30天的毫秒数)
            let secondNum = 30 * 24 * 60 * 60 * 1000;
            if (timeOptionRange) {
                //如果有选中时间 设置超过选中时间后的30天||超过选中前的30天||大于当前时间 不可选
                return time.getTime() > timeOptionRange.getTime() + secondNum || time.getTime() <= timeOptionRange.getTime();
            } else {
                //如果没有选中时间（初始化状态） 设置当前时间后的时间不可选
                return time.getTime() < (Date.now() - 8.64e7);
            }
        },
        hotelOnPick({ maxDate, minDate }) {
            // 当选中了第一个日期还没选第二个
            // 只选中一个的时候自动赋值给minDate，当选中第二个后组件自动匹配，将大小日期分别赋值给maxDate、minDate
            if (minDate && !maxDate) {
                this.timeOptionRange = minDate;
            }
            // 如果有maxDate属性，表示2个日期都选择了，则重置timeOptionRange
            if (maxDate) {
                this.timeOptionRange = null;
            }
        },
        changeHotelDate(val) {
            if (val === null) {
                this.hotelParams.hotelCheckInDate = ''
                this.hotelParams.hotelCheckOutDate = ''
                this.hotelNight = 0
            } else {
                this.hotelParams.hotelCheckInDate = val[0]
                this.hotelParams.hotelCheckOutDate = val[1]
                this.hotelNight = this.$moment(val[1]).diff(this.$moment(val[0]), 'days')

            }
        },
        blurHotelDate() {
            this.timeOptionRange = null
        },
        searchHotel() {
            this.$refs['hotelForm'].validate((valid) => {
                if (valid) {
                    this.$router.push({
                        name: 'admin-hotel-search',
                        query: {
                            cityId: this.hotelParams.hotelCitySelector.cityId,
                            cityName: this.hotelParams.hotelCitySelector.cityCn,
                            checkInDate: this.$moment(this.hotelParams.hotelCheckInDate).format('YYYY-MM-DD'),
                            checkOutDate: this.$moment(this.hotelParams.hotelCheckOutDate).format('YYYY-MM-DD'),
                            keyword: this.hotelParams.hotelKeyword,
                            hotelStar: this.hotelParams.hotelStarType,
                            evectionNo: '',
                            redirectType: '000',
                            evectionType: 1, // 1是个人出差，2是出差单出差
                            canShowSendMsg: 1, // 是否可以显示勾选短信 0是不可以， 1是可以
                        }
                    })
                }
            })
        },
        disabledDate(time) {
            let month = this.$moment().month()
            let day = 0
            if (month === 0 || month === 1) {
                day = 61
            } else if (month === 6) {
                day = 64
            } else {
                day = 63
            }
            return (time.getTime() < Date.now() - 8.64e7) || (time.getTime() > (Date.now() - 8.64e7 + day * 24 * 60 * 60 * 1000))
        },
        changeTrainAdr() {
            let fromStation = Object.assign({}, this.trainParams.fromStation)
            let toStation = Object.assign({}, this.trainParams.toStation)
            this.trainParams.fromStation = toStation
            this.trainParams.toStation = fromStation
            this.$set(this.trainParams, 'fromStation', toStation)
            this.$set(this.trainParams, 'toStation', fromStation)
        },
        searchTrain() {
            this.$refs['trainForm'].validate((valid) => {
                if (valid) {
                    this.$router.push({
                        name: 'admin-train-search',
                        query: {
                            fromStationCode: this.trainParams.fromStation.stationCode,
                            toStationCode: this.trainParams.toStation.stationCode,
                            time: this.trainParams.time,
                            onlySearch: this.trainParams.onlySearch,
                            evectionNo: '',
                            redirectType: '000',
                            evectionType: 1, // 1是个人出差，2是出差单出差
                            canShowSendMsg: 1, // 是否可以显示勾选短信 0是不可以， 1是可以
                        }
                    })
                }
            })
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
